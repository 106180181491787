import {
    ContractState,
    DPAContract,
    DPAContracts,
    DPAContractsByCompanyId,
} from "../redux/dpa-contracts/dpa-contracts-types";
import {
    AlarmCustomization,
    AlarmSettings,
    Apartment,
    ChangeMeterPayload,
    FetchGraphsSuccessPayload,
    FetchGraphsSuccessPayloadEnergyMeterData,
    FetchGraphsSuccessPayloadTemperatureMeterData,
    FetchGraphsSuccessPayloadWaterMeterData,
    Meter,
    MeterType,
    PremiumSettings,
    ReportFormat,
    ReportResoluton,
    ReportSettings,
} from "../redux/apartments/apartments-types";
import { Client, ClientCommand } from "../redux/centers/centers-types";
import {
    AlarmType,
    AlarmTypeLocalizations,
    Company,
    CompanyInvoiceSettings,
    CreateCompanyPayload,
    Localization,
    Manager,
    Role,
    SaveCompanyInvoicePayload,
    SaveCompanyPayload,
    UpdateUserRoleAsManagerPayload,
} from "../redux/companies/companies-types";
import { Note } from "../redux/notes/notes-types";
import { Report } from "../redux/reports/reports-types";
import { Credentials, Resident, ResidentData } from "../redux/residents/residents-types";
import { CreateUserPayload, UpdateUserPayload, User } from "../redux/users/users-types";
import {
    AlarmCustomizationRequestPayload,
    ApiAlarmCustomization,
    ApiAlarmSettings,
    ApiAlarmType,
    ApiMeterAlarmHistory,
    ApiApartment,
    ApiClient,
    ApiClientCommand,
    ApiCompanyForCompanyList,
    ApiCredentials,
    ApiDPAContract,
    ApiDPAContracts,
    ApiDPAContractsByCompanyId,
    ApiEnergyMeterData,
    ApiLocalization,
    ApiManager,
    ApiManagerRole,
    ApiMeter,
    ApiMeterType,
    ApiNote,
    ApiPremiumSettings,
    ApiReport,
    ApiReportFormat,
    ApiReportResoluton,
    ApiReportSettings,
    ApiResident,
    ApiResidentData,
    ApiResidents,
    ApiSmokeAlarms,
    ApiTemperatureMeterData,
    ApiUser,
    ApiWaterMeterData,
    ChangeMeterRequestPayload,
    CreateCompanyRequestPayload,
    CreateUserRequestPayload,
    FetchCompanyInvoiceSuccessResponsePayload,
    FetchGraphsSuccessResponse,
    PremiumSettingsRequestPayload,
    ReportSettingsRequestPayload,
    SaveCompanyInvoiceRequestPayload,
    SaveCompanyRequestPayload,
    UpdateUserRequestPayload,
    UpdateUserRoleAsManagerRequestPayload,
} from "./api-types";
import { MeterAlarmHistoryEvent, SmokeAlarms } from "../redux/smoke-alarms/smoke-alarms-types";

export function convertApiCompany(apiCompany: ApiCompanyForCompanyList): Company {
    return {
        id: apiCompany.id,
        name: apiCompany.name ?? "",
        premium: apiCompany.premium,
        streetAddress: apiCompany.streetAddress ?? "",
        town: apiCompany.town ?? "",
        managers: apiCompany.managers.map(convertApiManager),
        alarmNames: [],
        alarmLoadingState: undefined,
        zipCode: apiCompany.zipCode ?? "",
        apiAccessEnabled: apiCompany.apiAccessEnabled,
        reportFormat: apiCompany.reportFormat,
        clients: sortClients(apiCompany.clients.map((client) => convertApiClient(client))),
        displayNewPrincipalManagerMessage: apiCompany.displayNewPrincipalManagerMessage,
    };
}

function sortClients(clients: Client[]): Client[] {
    return clients.sort((a, b) => {
        if (a.id < b.id) return -1;
        return 1;
    });
}

export function convertApiManager(apiManager: ApiManager): Manager {
    return {
        id: apiManager.id,
        userId: apiManager.userId,
        role: convertApiManagerRole(apiManager.role),
        principal: apiManager.principal,
    };
}

export function convertApiManagerRole(apiManagerRole: ApiManagerRole): Role {
    switch (apiManagerRole) {
        case "admin":
            return "admin";

        case "manager":
            return "manager";

        case "viewer":
            return "viewer";

        default:
            throw new Error(`${apiManagerRole} does not match Role (unknown type)`);
    }
}

export function convertApiApartment(apiApartment: ApiApartment): Apartment {
    return {
        id: apiApartment.id,
        code: apiApartment.code,
        clientId: apiApartment.clientId,
        meters: convertApiMeters(apiApartment.meters),
    };
}

function convertApiMeters(apiMeters: ApiMeter[]): any {
    const meters: Meter[] = [];

    apiMeters.forEach((m) => {
        if (m.type === "ambient") {
            meters.push(convertApiMeter(m, String(m.id) + ":temperature"));
            meters.push(convertApiMeter(m, String(m.id) + ":humidity", true));
        } else if (m.type === "energy") {
            meters.push(convertApiMeter(m, String(m.id) + energyReadingType(m)));
        } else {
            meters.push(convertApiMeter(m));
        }
    });
    return meters;
}

function energyReadingType(m: ApiMeter): string {
    if (m.heating) {
        return ":heating";
    } else if (m.cooling) {
        return ":cooling";
    } else {
        return ":electricity";
    }
}

export function convertApiClient(apiClient: ApiClient): Client {
    return {
        id: apiClient.id,
        code: apiClient.code,
        softwareVersion: apiClient.softwareVersion ?? undefined,
        telephoneNumber: apiClient.telephoneNumber ?? undefined,
        commandQueue: apiClient.commandQueue,
        supportedCommands: apiClient.supportedCommands,
    };
}

export function convertApiMeter(apiMeter: ApiMeter, id?: string, humidity?: boolean): Meter {
    return {
        id: id ?? String(apiMeter.id),
        type: convertApiMeterType(apiMeter.type),
        warm: apiMeter.warm ?? false,
        heating: apiMeter.heating ?? false,
        cooling: apiMeter.cooling ?? false,
        humidity: humidity ?? false,
        code: apiMeter.code,
        secondaryAddress: apiMeter.secondaryAddress ?? null,
        reading: humidity ? apiMeter.humidity ?? null : apiMeter.reading ?? null,
        updated: apiMeter.updated ? parseInt(apiMeter.updated) : 0,
        meterResetAt: apiMeter.meterResetAt ? new Date(apiMeter.meterResetAt) : null,
        earliestReading: apiMeter.earliestReading ? new Date(apiMeter.earliestReading) : null,
        activeAlarmNames: apiMeter.activeAlarmNames,
        hasActivePremiumAlarms: apiMeter.hasActivePremiumAlarms,
        supportsHydrodigitLRAlarmReset: apiMeter.supportsHydrodigitLRAlarmReset,
        deactivatedAlarmTypes: apiMeter.deactivatedAlarmTypes,
        devEui: apiMeter.devEui ?? null,
    };
}

export function convertApiMeterType(apiMeterType: ApiMeterType): MeterType {
    switch (apiMeterType) {
        case "energy":
            return "energy";

        case "leakage":
            return "leakage";

        case "ambient":
            return "ambient";

        case "water":
            return "water";

        case "smoke":
            return "smoke";

        default:
            throw new Error(`${apiMeterType} does not match MeterType (unknown type)`);
    }
}

export function convertApiPremiumSettings(apiPremiumSettings: ApiPremiumSettings): PremiumSettings {
    return {
        phoneNumbers: apiPremiumSettings.phoneNumbers ?? "",
        warmWaterPrice: apiPremiumSettings.warmWaterPrice ?? "",
        coldWaterPrice: apiPremiumSettings.coldWaterPrice ?? "",
        energyPrice: apiPremiumSettings.energyPrice ?? "",
        billingStart:
            apiPremiumSettings.billingStart !== null && apiPremiumSettings.billingStart.trim() !== ""
                ? apiPremiumSettings.billingStart
                : null,
        billingPeriodMonths: apiPremiumSettings.billingPeriodMonths ?? null,
    };
}

export function convertApiReportSettings(apiReportSettings: ApiReportSettings): ReportSettings {
    return {
        reportingId: apiReportSettings.reportingId,
        sendReports: apiReportSettings.sendReports,
        reportEmail: apiReportSettings.reportEmail,
        reportFormat: apiReportSettings.sendReports ? apiReportSettings.reportFormat : "",
        reportIntervalMonths: apiReportSettings.reportIntervalMonths ?? 0,
        reportLang: apiReportSettings.reportLang,
        reportLastSent: apiReportSettings.reportLastSent,
        reportStart: apiReportSettings.reportStart,
    };
}

export function convertApiClientCommand(apiClientCommand: ApiClientCommand): ClientCommand {
    return {
        tag: apiClientCommand.tag,
        nameByLang: apiClientCommand.nameByLang,
        requiresAdmin: apiClientCommand.requiresAdmin,
    };
}

export function convertApiNote(apiNote: ApiNote): Note {
    return {
        id: apiNote.id,
        created: new Date(apiNote.created),
        comment: apiNote.comment,
        creator: apiNote.creator,
    };
}

export function convertApiDPAContract(apiDPAContract: ApiDPAContract): DPAContract {
    return {
        id: apiDPAContract.id,
        createdAt: apiDPAContract.createdAt,
        updatedAt: apiDPAContract.updatedAt,
        provider: apiDPAContract.provider,
        providerContractId: apiDPAContract.providerContractId,
        state: toContractState(apiDPAContract.state),
    };
}

export function convertApiDPAContractsByCompanyId(
    apiDPAContractsByCompanyId: ApiDPAContractsByCompanyId,
): DPAContractsByCompanyId {
    const dPAContractsByCompanyId: DPAContractsByCompanyId = {};

    for (let a in apiDPAContractsByCompanyId) {
        dPAContractsByCompanyId[a] = apiDPAContractsByCompanyId[a].map((d) => convertApiDPAContract(d));
    }
    return dPAContractsByCompanyId;
}

export function convertApiDPAContracts(apiDPAContract: ApiDPAContracts): DPAContracts {
    return {
        userDpaContracts: apiDPAContract.userDpaContracts.map((d) => convertApiDPAContract(d)),
        companyDpaContractsByCompanyId: convertApiDPAContractsByCompanyId(
            apiDPAContract.companyDpaContractsByCompanyId,
        ),
    };
}

function toContractState(contractString: string): ContractState {
    switch (contractString) {
        case "PENDING":
            return ContractState.PENDING;
        case "ACTIVE":
            return ContractState.ACTIVE;
        case "ENDED":
            return ContractState.ENDED;
        case "CANCELLED":
            return ContractState.CANCELLED;
    }
    return ContractState.UNKNOWN;
}

export function convertApiUser(apiUser: ApiUser): User {
    return {
        id: apiUser.id,
        email: apiUser.email,
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
        phone: apiUser.phone ?? "",
        admin: apiUser.admin,
    };
}

export function convertCreateUserPayload(createUserPayload: CreateUserPayload): CreateUserRequestPayload {
    return {
        admin: createUserPayload.admin,
        email: createUserPayload.email,
        firstName: createUserPayload.firstName,
        lastName: createUserPayload.lastName,
        phone: createUserPayload.phone,
    };
}

export function convertUpdateUserPayload(updateUserPayload: UpdateUserPayload): UpdateUserRequestPayload {
    return {
        firstName: updateUserPayload.firstName,
        lastName: updateUserPayload.lastName,
        phone: updateUserPayload.phone,
    };
}

export function convertApiFetchResidents(residents: ApiResidents): Resident[] {
    let newResidentArray: Resident[] = [];
    for (let r in residents) {
        const key = parseInt(r);
        const apiResident = residents[key];
        if (apiResident) {
            const resident = convertApiFetchResident(key, apiResident);
            newResidentArray.push(resident);
        }
    }
    return newResidentArray;
}

function convertApiFetchResident(apartmentId: number, resident: ApiResident): Resident {
    return {
        apartmentId: apartmentId,
        main: convertApiResidentData(resident.main),
        other: convertApiResidentData(resident.other),
    };
}

function convertApiResidentData(data: ApiResidentData | null): ResidentData | null {
    if (!data) return null;
    return {
        id: data.id,
        email: data.email,
        dataVisibleFrom: {
            year: data.dataVisibleFrom.year,
            month: data.dataVisibleFrom.month,
        },
    };
}

function convertWaterMeterData(waterMeterData: ApiWaterMeterData): FetchGraphsSuccessPayloadWaterMeterData {
    return {
        created: waterMeterData.created,
        usageLitres: waterMeterData.usageLitres,
    };
}

function convertEnergyMeterData(energyMeterData: ApiEnergyMeterData): FetchGraphsSuccessPayloadEnergyMeterData {
    return {
        created: energyMeterData.created,
        usageKwh: energyMeterData.usageKwh,
    };
}

function convertTemperatureMeterData(
    temperatureMeterData: ApiTemperatureMeterData,
): FetchGraphsSuccessPayloadTemperatureMeterData {
    return {
        timestamp: temperatureMeterData.timestamp,
        averageTemperatureCelsius: temperatureMeterData.averageTemperatureCelsius,
        averageHumidityPercent: temperatureMeterData.averageHumidityPercent,
    };
}

export function convertFetchGraphsSuccessResponse(res: FetchGraphsSuccessResponse): FetchGraphsSuccessPayload {
    const usageByMeterId = res.usageByMeterId;
    const usageByEnergyMeterId = res.usageByEnergyMeterId;
    const byTemperatureMeterId = res.byTemperatureMeterId;

    const newUsageByMeterId: Record<number, FetchGraphsSuccessPayloadWaterMeterData[]> = {};
    const newHeatingUsageByEnergyMeterId: Record<number, FetchGraphsSuccessPayloadEnergyMeterData[]> = {};
    const newCoolingUsageByEnergyMeterId: Record<number, FetchGraphsSuccessPayloadEnergyMeterData[]> = {};
    const newElectricityUsageByEnergyMeterId: Record<number, FetchGraphsSuccessPayloadEnergyMeterData[]> = {};
    const newByTemperatureMeterId: Record<number, FetchGraphsSuccessPayloadTemperatureMeterData[]> = {};

    for (let a in usageByMeterId) {
        let key = parseInt(a);
        let water = usageByMeterId[key];
        if (water) {
            newUsageByMeterId[key] = water.map((w) => convertWaterMeterData(w));
        }
    }
    for (let b in usageByEnergyMeterId) {
        let key = parseInt(b);
        let heating = usageByEnergyMeterId[key]["HEATING_ENERGY"];
        if (heating) {
            newHeatingUsageByEnergyMeterId[key] = heating.map((e) => convertEnergyMeterData(e));
        }
        let cooling = usageByEnergyMeterId[key]["COOLING_ENERGY"];
        if (cooling) {
            newCoolingUsageByEnergyMeterId[key] = cooling.map((e) => convertEnergyMeterData(e));
        }
        let electricity = usageByEnergyMeterId[key]["ELECTRICITY_ENERGY"];
        if (electricity) {
            newElectricityUsageByEnergyMeterId[key] = electricity.map((e) => convertEnergyMeterData(e));
        }
    }
    for (let c in byTemperatureMeterId) {
        let key = parseInt(c);
        let temperature = byTemperatureMeterId[key];
        if (temperature) {
            newByTemperatureMeterId[key] = temperature.map((t) => convertTemperatureMeterData(t));
        }
    }
    return {
        usageByMeterId: newUsageByMeterId,
        heatingUsageByEnergyMeterId: newHeatingUsageByEnergyMeterId,
        coolingUsageByEnergyMeterId: newCoolingUsageByEnergyMeterId,
        electricityUsageByEnergyMeterId: newElectricityUsageByEnergyMeterId,
        byTemperatureMeterId: newByTemperatureMeterId,
    };
}

export function convertApiAlarmSettings(res: ApiAlarmSettings): AlarmSettings {
    return {
        stuckEmail: res.stuckEmail,
        alarmLang: res.alarmLang,
        alarmCustomizations: res.alarmCustomizations.map((a) => convertAlarmCustomization(a)),
    };
}

function convertAlarmCustomization(res: ApiAlarmCustomization): AlarmCustomization {
    return {
        companyId: res.companyId,
        alarmType: res.alarmType,
        highPriority: res.highPriority,
        notifyOnNew: res.notifyOnNew,
        alarmInterval: res.alarmInterval,
    };
}

export function convertApiReportFormat(res: ApiReportFormat): ReportFormat {
    return {
        id: res.id,
        name: res.name,
        resolution: convertApiReportResolution(res.resolution),
    };
}

function convertApiReportResolution(resolution: ApiReportResoluton): ReportResoluton {
    return resolution === "month" ? ReportResoluton.MONTH : ReportResoluton.DAY;
}

export function createPremiumSettingsRequestPayload(req: PremiumSettings): PremiumSettingsRequestPayload {
    return {
        phoneNumbers: req.phoneNumbers,
        warmWaterPrice: req.warmWaterPrice,
        coldWaterPrice: req.coldWaterPrice,
        energyPrice: req.energyPrice,
        billingStart: req.billingStart,
        billingPeriodMonths: req.billingPeriodMonths,
    };
}

export function createCustomizationRequestPayload(req: AlarmCustomization): AlarmCustomizationRequestPayload {
    return {
        alarmType: req.alarmType,
        highPriority: req.highPriority,
        notifyOnNew: req.notifyOnNew,
        alarmInterval: req.alarmInterval,
    };
}

export function createReportSettingsRequestPayload(req: ReportSettings): ReportSettingsRequestPayload {
    return {
        reportingId: req.reportingId,
        sendReports: req.sendReports,
        reportEmail: req.reportEmail,
        reportFormat: req.reportFormat,
        reportIntervalMonths: req.reportIntervalMonths,
        reportLang: req.reportLang,
        reportStart: req.reportStart,
    };
}

export function createChangeMeterRequestPayload(req: ChangeMeterPayload): ChangeMeterRequestPayload {
    return {
        apartmentId: req.apartmentId,
        date: req.date,
        valueBefore: req.valueBefore,
        valueAfter: req.valueAfter,
    };
}

export function createCreateCompanyRequestPayload(req: CreateCompanyPayload): CreateCompanyRequestPayload {
    return {
        name: req.name,
        streetAddress: req.streetAddress,
        zipCode: req.zipCode,
        town: req.town,
    };
}

export function saveCompanyRequestPayload(req: SaveCompanyPayload): SaveCompanyRequestPayload {
    return {
        name: req.name,
        streetAddress: req.streetAddress,
        zipCode: req.zipCode,
        town: req.town,
    };
}

export function createUpdateUserRoleAsManagerRequestPayload(
    req: UpdateUserRoleAsManagerPayload,
): UpdateUserRoleAsManagerRequestPayload {
    return {
        email: req.email,
        firstName: req.firstName,
        lastName: req.lastName,
        role: req.role,
        principal: req.principal,
    };
}

function createLocalization(loc: ApiLocalization): Localization {
    return {
        alarmName: loc.alarmName,
        explanation: loc.explanation,
    };
}

export function convertApiAlarmType(alarmType: ApiAlarmType): AlarmType {
    const fi: Localization = createLocalization(alarmType.localizations.fi);
    const sv: Localization = createLocalization(alarmType.localizations.sv);
    const en: Localization = createLocalization(alarmType.localizations.en);

    const alarmTypeLocalizations: AlarmTypeLocalizations = {
        fi: fi,
        sv: sv,
        en: en,
    };

    return {
        defaultInterval: alarmType.defaultInterval,
        requiresPremium: alarmType.requiresPremium,
        adminOnly: alarmType.adminOnly,
        visibleForUser: alarmType.visibleForUser,
        defaultHighPriority: alarmType.defaultHighPriority,
        defaultNotifyOnNew: alarmType.defaultNotifyOnNew,
        alarmName: alarmType.alarmName,
        localizations: alarmTypeLocalizations,
        supportedMeterTypes: alarmType.supportedMeterTypes,
    };
}

export function convertApiReport(res: ApiReport): Report {
    return {
        apartmentId: res.apartmentId,
        coldWaterMeterIds: res.coldWaterMeterIds,
        coldWaterConsumptionM3: res.coldWaterConsumptionM3,
        warmWaterMeterIds: res.warmWaterMeterIds,
        warmWaterConsumptionM3: res.warmWaterConsumptionM3,
        coolingEnergyMeterIds: res.coolingEnergyMeterIds,
        coolingEnergyConsumptionKwh: res.coolingEnergyConsumptionKwh,
        heatingEnergyMeterIds: res.heatingEnergyMeterIds,
        heatingEnergyConsumptionKwh: res.heatingEnergyConsumptionKwh,
        electricityEnergyMeterIds: res.electricityEnergyMeterIds,
        electricityEnergyConsumptionKwh: res.electricityEnergyConsumptionKwh,
    };
}

export function convertApiCredentials(res: ApiCredentials): Credentials {
    const newCredentials: Record<number, { password: string; username: string }> = {};

    for (let a in res) {
        let key = parseInt(a);
        let cred = res[key];
        if (cred) {
            newCredentials[key] = {
                password: cred.password,
                username: cred.username,
            };
        }
    }
    return newCredentials;
}

export function saveCompanyInvoiceRequestPayload(req: SaveCompanyInvoicePayload): SaveCompanyInvoiceRequestPayload {
    return {
        recipientName: req.recipientName,
        bank: req.bank,
        bic: req.bic,
        iban: req.iban,
        bankgirot: req.bankgirot,
        invoiceType: req.invoiceType,
        contactPerson: req.contactPerson,
        daysToPay: req.daysToPay,
        referenceNumberBody: req.referenceNumberBody,
    };
}

export function convertApiCompanyInvoiceInfo(res: FetchCompanyInvoiceSuccessResponsePayload): CompanyInvoiceSettings {
    return {
        recipientName: res.recipientName,
        bank: res.bank,
        bic: res.bic,
        iban: res.iban,
        bankgirot: res.bankgirot,
        invoiceType: res.invoiceType,
        contactPerson: res.contactPerson,
        daysToPay: res.daysToPay,
        referenceNumberBody: res.referenceNumberBody,
    };
}

export function convertApiSmokeAlarms(apiSmokeAlarms: ApiSmokeAlarms): SmokeAlarms {
    return {
        apartmentName: apiSmokeAlarms.apartmentName,
        meterCode: apiSmokeAlarms.meterCode,
        activeAlarms: apiSmokeAlarms.activeAlarms,
        activatedDate: apiSmokeAlarms.activatedDate,
    };
}

export function convertApiAlarmHistoryEvent(apiMeterAlarmHistory: ApiMeterAlarmHistory): MeterAlarmHistoryEvent[] {
    return apiMeterAlarmHistory.alarms.map((alarm) => {
        return {
            apartmentName: apiMeterAlarmHistory.apartmentName,
            meterCode: apiMeterAlarmHistory.meterCode,
            alarmRuleName: alarm.alarmRuleName,
            alarmEnabledDate: alarm.alarmEnabledDate,
            alarmDisabledDate: alarm.alarmDisabledDate,
        };
    });
}
